export const enum PageType {
	Backoffice = 'backoffice',
	Checkout = 'checkout',
	ConfirmationPage = 'purchase',
	DiscoverCategory = 'discover_category',
	DiscoverCategoryBrand = 'discover_category_brand',
	DiscoverHome = 'discover_home',
	DiscoverPost = 'discover_post',
	Editorials = 'editorials',
	Error500 = 'error_500',
	Favorites = 'favorites',
	GiftVoucher = 'gift_voucher',
	Help = 'help',
	Home = 'home',
	Landings = 'landings',
	Login = 'login',
	MangoLikesYou = 'mango_likes_you',
	MyMangoLikesYou = 'my_mango_likes_you',
	MyAccount = 'my_account',
	MyPurchases = 'my_purchases',
	NewsletterDoubleOptinConfirmation = 'newsletter-double-optin-confirmation',
	NotFound = 'not_found',
	PDP = 'pdp',
	PLP = 'plp',
	PrivacyPolicy = 'privacy_policy',
	Refunds = 'refunds',
	Registry = 'registry',
	Search = 'search',
	ShoppingCart = 'bag',
	SizeGuide = 'size_guide',
	FashionAssistant = 'fashion_assistant',
	Unknown = 'unknown',
	MyReturns = 'my_returns',
	MyAddresses = 'my_addresses',
}
